const server = import.meta.server;

export default function () {
    const config = useRuntimeConfig();
    const { locale } = useI18n();
    const xsrfToken = useState<string>("xsrfToken");

    return $fetch.create({
        baseURL: server ? config.apiUrl : config.public.baseUrl,
        onRequest({ options }) {
            options.headers.set("CSRF-Token", xsrfToken.value);
            options.query = { lang: locale.value, ...options.query };
        },
    });
}
